.header {
  background-color: #fff;
  width: 100%;
  height: 35px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 50px;
  padding-left: 50px;
  font-size: smaller;
}

.sign-out {
  margin-left: auto;
  font-size: smaller;
}

